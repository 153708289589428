import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Layout from '../containers/layout'
import tw, { theme, css } from 'twin.macro'
import { Helmet } from 'react-helmet'

const shortcodes = { Link } // Provide common components here

export default function PageTemplate({ data: { mdx }, data }) {

  const site = data.site
  const siteTitle = site.siteMetadata.title
  const domain = site.siteMetadata.siteUrl

  const desc =
    'One of my lifelong goals is to create a fulfilling and balanced lifestyle and extend it to the people I love and care for the most. Success with happiness and joy is the best kind of success.'

  return (
    <Layout>
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
        title={`About · ${siteTitle}`}
      >
        <meta name="description" content={desc} />
        <meta name="image" content={`${domain}${data.file.childImageSharp.fluid.src}`} />

        <meta name="twitter:title" content={`About · ${siteTitle}`} />
        <meta name="twitter:description" content={desc} />
        <meta
          property="twitter:image"
          content={`${domain}${data.file.childImageSharp.fluid.src}`}
        />
        <meta property="twitter:card" content="summary" />

        <meta name="og:title" content={`About · ${siteTitle}`} />
        <meta name="og:description" content={desc} />
        <meta property="og:image" content={`${domain}${data.file.childImageSharp.fluid.src}`} />
        <meta name="og:type" content="website" />
      </Helmet>
      <section tw="w-full">
        <div
          tw="mt-5 pt-5 md:mt-16 md:pt-20 mx-6 md:mx-auto grid grid-cols-1 md:grid-cols-2 font-serif pb-10 md:pb-16 border-b "
          css={{
            width: 'min(1024px, calc(100% - 48px))',
            gridRowGap: 8,
            borderColor: `${theme`borderColor.accent`}`,
          }}
        >
          <h1 tw="text-3xl lg:text-4xl col-span-1 font-semibold ">About Me</h1>
          <p tw="col-span-1 text-softer prose prose-lg xl:prose-xl">{desc}</p>
        </div>
      </section>
      <main tw="mt-10 md:mt-24 font-serif mb-24 md:mb-32">
        <article
          tw="font-serif prose prose-lg xl:prose-xl max-w-none"
          css={{
            display: 'grid',
            gridTemplateColumns: '1fr min(650px, calc(100% - 48px)) 1fr',
            '& > *': {
              gridColumn: 2,
            },
            '& > .form': {
              gridColumn: '1/-1',
            },
            '@media (min-width: 1024px)': {
              '& > aside': {
                gridColumn: 3,
                padding: '1.75rem',
                fontSize: '0.875rem',
                lineHeight: '1.25rem',
                maxWidth: '400px',
              },
              '& > p:first-of-type + aside': {
                paddingTop: '0.5rem',
              },
            },
          }}
        >
          <MDXProvider components={shortcodes}>
            <MDXRenderer>{mdx.body}</MDXRenderer>
          </MDXProvider>
        </article>
      </main>
    </Layout>
  )
}

export const pageQuery = graphql`
  query AboutPageQuery($id: String) {
    site {
      siteMetadata {
        siteUrl
        title
      }
    }
    file(relativePath: { eq: "og-image.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1024, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        template
        title
        date
      }
    }
  }
`